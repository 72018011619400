@font-face {
    font-family: 'PressStart2P';
    src: url('../public/PressStart2P-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    height: 100%;
    margin: 0;
    overflow: hidden;
    touch-action: pan-down;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-container {
    height: calc(var(--vh, 1vh) * 100);
}

.retro-glow {
    box-shadow: 0px 0px 11px rgb(0, 0, 255);
    text-shadow: 0px 0px 4px rgb(0, 0, 255);
    color: rgb(78, 128, 238);
}


/* In your global CSS file */
.retro-divider {
    height: 4px; /* Adjust the height as needed */
    background: rgb(78, 128, 238);
    border-top: 2px dotted #000;
    border-bottom: 2px dotted #000;
}
